<template>
    <div class="setting-vendor-terms-of-service">
        <edit :fs="termsOfServiceFields" api="settings" id="vendor-terms-of-service" title="Vendor Terms of Service"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { termsOfServiceFields } from "@/config";
export default {
    components: {
        Edit
    },
    computed: {
        termsOfServiceFields() {
            return termsOfServiceFields;
        }
    }
};
</script>
<style lang="less">
.setting-vendor-terms-of-service {
    .md-input-container {
        label {
            // width: 0;
            display: none;
        }
    }
}
</style>


